import Vue from 'vue'
import App from './App.vue'
import VueScheduler from 'v-calendar-scheduler'
import './styles/style.scss'
import moment from 'moment'
import vmodal from 'vue-js-modal';
import VueTheMask from 'vue-the-mask'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Toasted from 'vue-toasted'

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)

Vue.use(VueScheduler, {
  locale: 'cs',
  labels: {
    today: 'Dnes',
    back: '<',
    next: '>',
    month: 'Měsíc',
    week: 'Týden',
    day: 'Den',
    all_day: 'Celý den'
 },
  showTimeMarker: true,
  timeRange: [6,20],
  minDate: moment(),
  maxDate: moment().add(1, 'year'),
})

Vue.use(vmodal)
Vue.use(VueTheMask)
Vue.use(Toasted, {
  position: "bottom-right",
  className: "toast__inner",
  containerClass: "toast__container",
  duration: "2000"
})

const pinia = createPinia()

new Vue({
  el: '#app',
  render: h => h(App),
  pinia,
})