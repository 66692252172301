import { defineStore } from "pinia"
import PocketBase from "pocketbase"

const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL)

export const useAuthStore = defineStore("AuthStore", {
    state: () => {
        if (localStorage.getItem("authData")) {
            return { authData: JSON.parse(localStorage.getItem("authData")) }
        } else {
            return { authData: null }
        }
    },
    getters: {
        getAuthData: (state) => state.authData,
    },
    actions: {
        setAuthData(data) {
            this.authData = data
            console.log(this.authData)
        },
        async getCurrentData() {
            pb.collection("users")
                .getOne(this.authData.record.id)
                .then((record) => {
                    this.setAuthData({ ...this.authData, record })
                })
                .catch((err) => {
                    this.setAuthData(null)
                    console.log(err)
                })
        },
    },
})
