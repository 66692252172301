<template>
  <div>
    <main-logged v-if="authStore.authData?.record" />
    <logging-in v-else-if="authLoading" />
    <login-form :login-error="loginError" v-else />
  </div>
</template>

<script>
import { useAuthStore } from "./stores/AuthStore"
import PocketBase from "pocketbase"
import Cookies from "js-cookie"

const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL)

export default {
  name: "App",
  data() {
    return {
      authLoading: false,
      loginError: null,
    }
  },
  components: {
    LoginForm: () => import("./components/LoginForm.vue"),
    LoggingIn: () => import("./components/LoggingIn.vue"),
    MainLogged: () => import("./components/MainLogged.vue"),
  },
  setup() {
    const authStore = useAuthStore()

    authStore.$subscribe(() => {
      localStorage.setItem("authData", JSON.stringify(authStore.authData))
    })

    return {
      authStore,
    }
  },
  mounted() {
    const params = new URL(window.location).searchParams
    const code = params.get("code")
    const state = params.get("state")
    const error = params.get("error_description")

    if (!pb.authStore.isValid) {
      console.log(pb.authStore.isValid, "valid")
      pb.authStore.clear()
      localStorage.removeItem("authData")
      this.authStore.setAuthData(null)
    }

    if (code && state) {
      const provider = JSON.parse(atob(Cookies.get("provider")))

      if (!provider?.codeVerifier) {
        this.loginError =
          "Nepodařilo se ověřit přihlášení. Nepoužíváš anonymní režim?"
        return
      }
      this.authLoading = true

      pb.collection("users")
        .authWithOAuth2(
          "muni",
          code,
          provider.codeVerifier,
          process.env.VUE_APP_POCKETBASE_REDIRECT_URL
        )
        .then(async (authData) => {
          history.pushState(null, "", location.href.split("?")[0])
          authData.record.name =
            authData.meta.rawUser.given_name +
            " " +
            authData.meta.rawUser.family_name

          if (!authData.meta.rawUser.eduperson_entitlement) {
            this.authLoading = false
            this.loginError =
              "Je nutné povolit eduperson_entitlement v Jednotném přihlášení pro rozpoznání pracoviště."
            return
          }

          let team = []

          await authData.meta.rawUser.eduperson_entitlement.forEach(
            (entitlement) => {
              if (entitlement.includes("workplace:")) {
                let split = entitlement.split(":")
                let teamLocal = split[split.length - 1].split("#")[0]
                team.push(teamLocal)
              }
            }
          )

          if (team.length > 1 && team.includes("970000")) {
            team = await team.filter((team) => team !== "970000")[0]
          } else {
            team = team[0]
          }

          if (!team.startsWith("97")) {
            this.authLoading = false
            this.loginError =
              "Pro použití aplikace musíte být zaměstnanec/kyně MUNI CZS."
            return
          }
          await pb.collection("users").update(authData.record.id, {
            lastLogin: new Date(),
            name: authData.record.name,
            team: team,
          })

          this.authStore.setAuthData(authData)
          this.authLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.authLoading = false
        })
    } else if (error) {
      this.loginError = error
      history.pushState(null, "", location.href.split("?")[0])
    }
  },
}
</script>

<style></style>
